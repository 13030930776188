import "./Modal.scss";
import {translatedTexts} from "../../utils/Data";
import {escapeHtml} from "../../utils/Data";


function Modal(props){

	return (
			<div className="modal-wrapper__icon">
				{props.icon === 'Rotate' && <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 40 40" fill="none">
					<path d="M28.5277 22.2918V10.7449L19.9167 7L11.3057 10.7449V22.2918L19.9167 26.3748L28.5277 22.2918Z" stroke="black" strokeMiterlimit="10"/>
					<path d="M11.3057 10.7449L19.9057 14.7419L28.5221 10.7449" stroke="black" strokeMiterlimit="10"/>
					<path d="M19.9199 14.7419V26.3748" stroke="black" strokeMiterlimit="10"/>
					<path d="M29.5322 27.8817C26.5124 30.5674 23.3369 32 20.06 32C16.7831 32 13.6076 30.5675 10.5879 27.8817" stroke="black"/>
					<path d="M14.3716 27.8115L10.068 27.7904L10.049 31.6674" stroke="black"/>
					<path d="M29.744 31.7498L29.7721 27.6798L25.6718 27.7747" stroke="black"/>
				</svg>}

				{props.icon === 'Move' && <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 40 40" fill="none">
					<path d="M23.2531 7.52209L19.7583 4L16.415 7.36931" stroke="black"/>
					<path d="M7.49487 16.0729L4 19.5949L7.34327 22.9642" stroke="black"/>
					<path d="M16.2801 32.4779L19.7749 36L23.1182 32.6307" stroke="black"/>
					<path d="M32.5051 23.16L36 19.638L32.6567 16.2687" stroke="black"/>
					<path d="M19.6904 5L19.6904 15.7551M19.6904 36L19.6904 29.697" stroke="black"/>
					<path d="M36 19.6273H28.4477M4 19.6273H10.646" stroke="black"/>
					<path d="M28.1826 26.3131V14.9153L19.6826 11.2186L11.1826 14.9153V26.3131L19.6826 30.3435L28.1826 26.3131Z" stroke="black" strokeMiterlimit="10" strokeLinecap="square"/>
					<path d="M11.1826 14.9152L19.6718 18.8606L28.1771 14.9152" stroke="black" strokeMiterlimit="10"/>
					<path d="M19.6846 18.8608V30.3437" stroke="black" strokeMiterlimit="10"/>
				</svg>}

				{props.icon === 'Scale' &&<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 40 40" fill="none">
					<path d="M24.5117 23.1939V16.7708L19.5996 14.6876L14.6875 16.7708V23.1939L19.5996 25.4651L24.5117 23.1939Z" stroke="#838383" strokeWidth="0.75" strokeMiterlimit="10" strokeLinecap="square" strokeDasharray="3 3"/>
					<path d="M14.6875 16.7712L19.5934 18.9945L24.5085 16.7712" stroke="#838383" strokeWidth="0.75" strokeMiterlimit="10" strokeDasharray="3 3"/>
					<path d="M19.6016 18.994V25.465" stroke="#838383" strokeWidth="0.75" strokeMiterlimit="10" strokeDasharray="3 3"/>
					<path d="M27.9279 25.604V14.206L19.4278 10.5093L10.9277 14.206V25.604L19.4278 29.6343L27.9279 25.604Z" stroke="black" strokeMiterlimit="10" strokeLinecap="square"/>
					<path d="M10.9277 14.2061L19.417 18.1515L27.9224 14.2061" stroke="black" strokeMiterlimit="10"/>
					<path d="M19.4307 18.1516V29.6345" stroke="black" strokeMiterlimit="10"/>
					<path d="M34.376 10.757L34.376 5.50981L29.3564 5.50983" stroke="black"/>
					<path d="M5.62401 29.2431L5.62398 34.4903L10.6436 34.4903" stroke="black"/>
				</svg> }

				<div className="modal-wrapper__icon-text">{escapeHtml(translatedTexts.modal[props.icon.toLowerCase()])}</div>
		</div>
	);
}

export default Modal;
