import "./error.scss";

function ErrorPage(){

	return (
		<div className="error-page">
			<h2 className="error-page__h2">Sorry, an Error occured!</h2>
			<p className="error-page__text">Please try later in a few minutes</p>
		</div>
);
}

export default ErrorPage;
