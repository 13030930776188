let translatedTexts;

function setTexts(texts) {
  translatedTexts = texts;
}

export { setTexts, translatedTexts };
const swa3dConfig = window.swa3dConfig ?? {};

const modelConfig = swa3dConfig.modelConfig || {
  rose:{
    alt: "",
    orbit: "auto 90deg 28cm",
    orbitMobile: "auto 90deg 20cm",
    maxOrbit: "auto 90deg 35cm",
    skybox: "assets/skyboxes/swa_tint18.hdr",
    exposure: "1.4",
    id: "5230478",
    assetPath: "assets/models/5230478.glb",
    assetPathIOS: "assets/models/5230478.usdz"
  },
  kap:{
    alt: "",
    orbit: "auto 90deg 25cm",
    orbitMobile: "auto 90deg 17cm",
    maxOrbit: "auto 90deg 30cm",
    skybox: "assets/skyboxes/swa_tint18.hdr",
    exposure: "1.4",
    id: "5615387",
    assetPath: "assets/models/5615387.glb",
    assetPathIOS: "assets/models/5615387.usdz"
  },
  ball:{
    alt: "",
    orbit: "auto 90deg 35cm",
    orbitMobile: "auto 90deg 20cm",
    maxOrbit: "auto 90deg 40cm",
    skybox: "assets/skyboxes/swa_tint18.hdr",
    exposure: "1.4",
    id: "5625988",
    assetPath: "assets/models/5625988.glb",
    assetPathIOS: "assets/models/5625988.usdz"
  },
  kapball:{
    alt: "",
    orbit: "auto 90deg 20cm",
    orbitMobile: "auto 90deg 13cm",
    maxOrbit: "auto 90deg 30cm",
    skybox: "assets/skyboxes/swa_tint18.hdr",
    exposure: "1.4",
    id: "5626016",
    assetPath: "assets/models/5626016.glb",
    assetPathIOS: "assets/models/5626016.usdz"
  },
  bell:{
    alt: "",
    orbit: "auto 90deg 35cm",
    orbitMobile: "auto 90deg 23cm",
    maxOrbit: "auto 90deg 40cm",
    skybox: "assets/skyboxes/swa_tint18.hdr",
    exposure: "1.4",
    id: "5626007",
    assetPath: "assets/models/5626007.glb",
    assetPathIOS: "assets/models/5626007.usdz"
  },
  bear:{
    alt: "",
    orbit: "auto 90deg 15cm",
    orbitMobile: "auto 90deg 12cm",
    maxOrbit: "auto 90deg 25cm",
    skybox: "assets/skyboxes/swa_tint26.hdr",
    exposure: "0.77",
    id: "5627747",
    assetPath: "assets/models/5627747.glb",
    assetPathIOS: "assets/models/5627747.usdz"
  },
};

export {modelConfig};

function escapeHtml(string) {
  string = string.replace('&nbsp;', String.fromCharCode(160));
  string = string.replace('&shy;', String.fromCharCode(173));
  return string
}
export {escapeHtml};
