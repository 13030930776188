import "./Info.scss";
import {translatedTexts} from "../../utils/Data";
import {escapeHtml} from "../../utils/Data";
import QRCode from "react-qr-code";

function Info(props){

	const isMobile = window.matchMedia('only screen and (max-width: 768px)').matches;
	const url = window.location.href;

	return (
		<div className="info-wrapper">
			<h2 className="info-wrapper_h2">{escapeHtml(translatedTexts[props.model].title)}</h2>
			<p className="info-wrapper_text">{isMobile ? translatedTexts[props.model].description : translatedTexts[props.model].descriptionDesktop}</p>
			{isMobile ?
				<>
					<button className="info-wrapper_button" slot="ar-button" id="mv-ar-button" onClick={()=>{props.setModal(true)}}>
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
							<path d="M2.18182 3.27273C2.18182 2.67273 2.67273 2.18182 3.27273 2.18182H5.45455V0H3.27273C1.47273 0 0 1.47273 0 3.27273V5.45455H2.18182V3.27273Z" fill="white"/>
							<path d="M20.7277 2.18182C21.3277 2.18182 21.8186 2.67273 21.8186 3.27273V5.45455H24.0004V3.27273C24.0004 1.47273 22.5277 0 20.7277 0H18.5459V2.18182H20.7277Z" fill="white"/>
							<path d="M3.27273 21.8181C2.67273 21.8181 2.18182 21.3272 2.18182 20.7272V18.5454H0V20.7272C0 22.5272 1.47273 24 3.27273 24H5.45455V21.8181H3.27273Z" fill="white"/>
							<path d="M20.7277 21.8181C21.3277 21.8181 21.8186 21.3272 21.8186 20.7272V18.5454H24.0004V20.7272C24.0004 22.5272 22.5277 24 20.7277 24H18.5459V21.8181H20.7277Z" fill="white"/>
							<path d="M18.8178 7.20001L12.8178 3.73092C12.316 3.43637 11.6833 3.43637 11.1815 3.73092L5.18146 7.20001C4.67964 7.49455 4.36328 8.0291 4.36328 8.61819V15.5455C4.36328 16.1346 4.67964 16.6691 5.18146 16.9636L11.1815 20.4327C11.6833 20.7273 12.316 20.7273 12.8178 20.4327L18.8178 16.9636C19.3196 16.6691 19.636 16.1346 19.636 15.5455V8.61819C19.636 8.0291 19.3196 7.49455 18.8178 7.20001ZM6.5451 15.2291V10.1891L10.9087 12.72V17.7491L6.5451 15.2291ZM11.9996 10.8327L7.63601 8.30183L11.9996 5.78183L16.3633 8.30183L11.9996 10.8327ZM13.0906 17.7491V12.72L17.4542 10.1891V15.2291L13.0906 17.7491Z" fill="white"/>
						</svg>
						{translatedTexts[props.model].button}
					</button>
				</>
				:
				<div className="info-wrapper_hint">
					<QRCode value={url} viewBox={`0 0 256 256`} bgColor={"#FFFFFF"} style={{height: "100px", width:"100px", marginRight:"32px"}}/>
					<div className="info-wrapper_text--bold">
						{translatedTexts[props.model].buttonDesktop}
					</div>
				</div>
			}
		</div>
);
}

export default Info;
