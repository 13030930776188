import React from "react";
import * as ReactDOM from 'react-dom/client';
import App from "./App";
import {
  createBrowserRouter,
    RouterProvider,
    Route,
} from "react-router-dom";
import ErrorPage from "./pages/errorPage/ErrorPage";


const router = (data) => createBrowserRouter([
	{
		path: "/",
		element: <App model={"rose"} texts={data} />,
		errorElement:<ErrorPage />
	},{
	path: "/5230478",
	element: <App model={"rose"} texts={data} />
	}
	,{
		path: "/5615387",
		element: <App model={"kap"} texts={data} />
	}
	,{
		path: "/5625988",
		element: <App model={"ball"} texts={data} />
	}
	,{
		path: "/5626007",
		element: <App model={"bell"} texts={data} />
	}
	,{
		path: "/5626016",
		element: <App model={"kapball"} texts={data} />
	}
	,{
		path: "/5627747",
		element: <App model={"bear"} texts={data} />
	}


]);

function render(data) {
  ReactDOM.createRoot(
    document.getElementById("root")
  ).render(
    <React.StrictMode>
      <RouterProvider router={router(data)} />
    </React.StrictMode>,
    )
}

function readJson(path, errorCallback = undefined) {
  fetch(path)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      render(data);
    })
    .catch(() => {
      if (errorCallback !== undefined) {
        errorCallback();
      }
    });
}
//For Production
let languagePath = window.swa3dConfig.textPath + "texts/texts_" + window.swa3dConfig.language + ".json";

//For development on local host
// let languagePath = "/assets/texts/texts_en.json";
let defaultLanguagePath = window.swa3dConfig.textPath + "texts/texts_" + window.swa3dConfig.defaultLanguage + ".json";

readJson(languagePath, () => {
  // load default language when there is an error
  readJson(defaultLanguagePath);
});
