import "./Modal.scss";
import {translatedTexts} from "../../utils/Data";
import ModalIcon from "./ModalIcon";
import useOnClickOutside from "../../utils/useOnClickOutside";
import {useRef} from "react";


function Modal(props){
	const closeRef = useRef(null);
	useOnClickOutside(closeRef, () => props.setModal(false));

	return (
		<div className="modal-wrapper" ref={closeRef} style={{bottom: props.isActive && "0"}}>

			<div className="modal-wrapper__close" onClick={()=>{props.setModal(false)}}>
				<svg xmlns="http://www.w3.org/2000/svg" width="9" height="8" viewBox="0 0 9 8" fill="none">
					<line x1="0.987342" y1="0.380455" x2="8.25336" y2="7.64648" stroke="black"/>
					<line x1="8.25297" y1="0.353553" x2="0.986947" y2="7.61957" stroke="black"/>
				</svg>
			</div>

			<div className="modal-wrapper__icons">
				<ModalIcon icon="Rotate"></ModalIcon>
				<ModalIcon icon="Move"></ModalIcon>
				<ModalIcon icon="Scale"></ModalIcon>
			</div>
			<div className="modal-wrapper__text">{translatedTexts.modal.intro}</div>
			<button className="modal-wrapper__button" onClick={()=> {
				props.handleClick();
			}}>{translatedTexts.modal.start}</button>
		</div>
	);
}

export default Modal;
