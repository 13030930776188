import "./Viewport.scss";


function Viewport({children}){


	return (
		<main className="main-viewport">
			{children}
		</main>
	);
}

export default Viewport;
