import "./ModelViewer.scss";
import {modelConfig} from "../../utils/Data";



function ModelViewer(props){
const path = modelConfig[props.model].assetPath;
const pathIOS = modelConfig[props.model].assetPathIOS;
const isMobile = window.matchMedia('only screen and (max-width: 768px)').matches;


	return (
		<model-viewer

			alt={modelConfig[props.model].alt}
			class="model-viewer"
			src={path}
			ios-src={pathIOS}
			interaction-prompt="auto"
			disable-tap
			ar
			auto-rotate
			auto-rotate-delay="1500"
			rotation-per-second="20deg"
			ar-modes="webxr quick-look"
			shadow-intensity="0"
			camera-controls
			touch-action="pan-y"
			disable-pan
			camera-orbit ={isMobile ? modelConfig[props.model].orbitMobile : modelConfig[props.model].orbit}
			max-camera-orbit = {modelConfig[props.model].maxOrbit}
			environment-image = {modelConfig[props.model].skybox}
			exposure={modelConfig[props.model].exposure}
	>
	<button className="default-ar-button" ref={props.buttonRef} slot="ar-button" id="intern-button">
		View AR Experience
	</button>
</model-viewer>
	);
}

export default ModelViewer;
