import React, {useRef, useState} from "react";
import "./swa3d.scss";
import { setTexts } from "./utils/Data";
import Viewport from "./components/viewport/Viewport";
import ModelViewer from "./components/model-viewer/ModelViewer";
import Info from "./components/info/Info";
import Modal from "./components/modal/Modal";
import {modelConfig} from "./utils/Data";

let arDataFound = false;
function fallbackStructuredData(model, texts) {
  if (arDataFound) {
    return false;
  }
  for(const structuredData of document.querySelectorAll('script[type="application/ld+json"]')) {
    const json = structuredData.innerText;
    const parsed = JSON.parse(json);
    if ((parsed['@type'] ?? '').toLowerCase() === '3dmodel') {
      arDataFound = true;
      break;
    }
  }
  if (!arDataFound) {
    const path = modelConfig[model].assetPath;
    const pathIOS = modelConfig[model].assetPathIOS;

    const arData = document.createElement('script');
    arData.type = 'application/ld+json';
    arData.innerText = JSON.stringify(JSON.parse(`{
                "@context": "http://schema.org/",
                "@type": "3DModel",
                "image": "${(document.querySelector('[property="og:image"]')).content }",
                "name": "${texts[model].title || 'Swarovski Ornament'}",
                "encoding": [
                    {
                        "@type": "MediaObject",
                        "contentUrl": "${path}?title=${encodeURIComponent(texts[model].title)}&link=${window.location.href}",
                        "encodingFormat": "model/gltf-binary"
                    },
                    {
                        "@type": "MediaObject",
                        "contentUrl": "${pathIOS}#callToAction=${encodeURIComponent('To Product')}&checkoutTitle=${encodeURIComponent(texts[model].title)}",
                        "encodingFormat": "model/vnd.usdz+zip"
                    }
                ]
            }`));
    document.body.appendChild(arData);
  }
  return true;
}

function App(props) {
  setTexts(props.texts);
  const ref = useRef(null);

  const [activeModal, setActiveModal] = useState(false);


  function handleModalClick(){
    setActiveModal(false);
    ref.current.click();
  }

  function setModal(active){
    setActiveModal(active);
  }

  try {
    fallbackStructuredData(props.model, props.texts);
  } catch(e) {
  }

  return (
    <>
      <Viewport>
        <ModelViewer model={props.model} buttonRef={ref}/>
        <Info model={props.model} setModal={setModal}/>
        <Modal isActive={activeModal} setModal={setModal} handleClick={handleModalClick}/>
      </Viewport>
    </>
  );
}

export default App;
